import(/* webpackMode: "eager" */ "/app/apps/main-landing/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@15.0.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.3__react@18.3.1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@15.0.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.3__react@18.3.1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.0.3_next@15.0.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.3__react@18.3.1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.3/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.3/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/AeonikPro-Light.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/AeonikPro-Regular.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/AeonikPro-RegularItalic.woff2\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./fonts/AeonikPro-Medium.woff2\",\"weight\":\"500\",\"style\":\"normal\"}],\"variable\":\"--font-aeonikpro\"}],\"variableName\":\"aeonikPro\"}");
